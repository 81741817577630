import React, { useEffect, useRef, useState } from 'react';
import parse from "html-react-parser";
import dynamic from 'next/dynamic';
import { ButtonGroup, Col, Container, Row, Spinner, ToggleButton } from "react-bootstrap";
import Select from "react-select";
import { withRouter } from "next/router";
import { apiHelper } from "../../helper/apiHelper";
import { jwtDecode } from "../../helper/jwtHelper";
import Link from "next/link";
import playCircle from "../../public/header/playCircle.svg";
import { useTranslation } from "next-i18next";
import { getCurrencyId } from "../../helper/utilHelper";
import Image from "next/image";

import styles from "./headerSection.module.scss";
import useDeviceWidth from "../../helper/useDeviceWidth";

const WatchVideoModal = dynamic(() => import('./watchVideoModal'));
const HeaderRating = dynamic(() => import('./rating'));

const Header = ({ serviceData,
    service_type_data,
    paper_types_data,
    display_pages_data, heroImage, ...props }) => {

    const { page_contents, guarantees_header_section } = serviceData || {};
    const { initial_content, initial_pitch_header } = page_contents || {};
    const [displayPagesData, setDisplayPagesData] = useState(display_pages_data);
    const [serviceId, setServiceId] = useState(1);
    const [deadline, setDeadline] = useState('');
    const [duration, setDuration] = useState('');
    const [deadlineLabel, setDeadlineLabel] = useState('Deadline');
    const [wordId, setWordId] = useState(1);
    const [couponCode, setCouponCode] = useState("");
    const [writingDropdown, setWritingDropdown] = useState([]);
    const [writeValue, setWriteValue] = useState(3);
    const [isServiceUpdated, setIsServiceUpdated] = useState(false);
    const [allServiceDropdown, setAllServiceDropdown] = useState([]);
    const [academicType, setAcademicType] = useState(3);
    const [serviceValue, setServiceValue] = useState({});
    const [dateDropdown,setDateDropdown] = useState([]);
    const [dateValue, setDateValue] = useState("Deadline");
    const [wordDropdown, setWordDropdown] = useState([]);
    const [wordValue, setWordValue] = useState("280 words / 1 page");
    const [wordIndex, setWordIndex] = useState(0);
    const [watchVideo, setWatchVideo] = useState(false);
    const [orderData, setOrder] = useState(null);
    const [selectValidation, setSelectValidation] = useState("");
    let selectRef = useRef();

    const { currLocale } = props;
    const { t } = useTranslation("common");

    const orderSubmitHandler = () => {
        if (selectRef.current.select.props.value.label === '' || selectRef.current.select.props.value.label === 'Deadline') {
            setSelectValidation("Select deadline before you order");
            selectRef.current.focus();
        } else {
            props.orderSubmitHandler();
        }
    }

    useEffect(() => {
        getServiceType();
        getAllPapers();
        getDeadlineFormat();
        getPages();
        setCoupon();
    }, []);

    const getServiceType = () => {
        if (service_type_data) {
            const serviceArr = service_type_data.map((info) => ({ name: info.type_of_service, value: info.type_of_service_id }));
            setWritingDropdown(serviceArr);
            setWriteValue(serviceArr[0].value);
        }
    }

    // API calling of get All papers
    const getAllPapers = () => {
        if (paper_types_data) {
            const data = paper_types_data;
            const curr_paper = serviceData && serviceData.paper_id
                ? parseInt(serviceData.paper_id)
                : 0;

            let filtered = data.popular_papers.filter(
                (item) => item.paper_id === curr_paper
            );

            if (filtered && filtered.length === 0) {
                filtered = data.other_papers.filter(
                    (item) => item.paper_id === curr_paper
                );
            }

            const selectedService =
                filtered && filtered.length === 0
                    ? data.popular_papers[0]
                    : filtered[0];

            const popularArr = (data.popular_papers).map((info) => ({ label: info.paper_name, value: info.paper_id, count_no: info.count_no }));
            const otherArr = (data.other_papers).map((info) => ({ label: info.paper_name, value: info.paper_id, count_no: info.count_no }));

            const serviceArr = [
                { label: 'Popular Papers', options: popularArr },
                { label: 'Other Papers', options: otherArr }
            ];

            setAllServiceDropdown(serviceArr);
            setServiceValue({ label: selectedService.paper_name, value: selectedService.paper_id });
            setServiceId(selectedService.paper_id);
        }

    }

    // API calling of get Deadline format
    const getDeadlineFormat = () => {
        const localTime = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezone = "Europe/London";
        let userToken = localStorage.getItem("token");
        if (userToken && userToken !== "") {
            userToken = JSON.parse(userToken);
            const decodeToken = jwtDecode(userToken.value);
            if (decodeToken && decodeToken.timezone && decodeToken.timezone !== "") {
                timezone = decodeToken.timezone;
            }
        }

        const formData = new FormData();
        formData.append("timezone", localTime ? localTime : timezone);

        apiHelper("displaydealineformat", "POST", formData, null)
            .then((res) => {
                if (res.data.status) {
                    const data = res.data.data;

                    const dateArr = data.map((info) => ({ label: `${info.deadline} ${info.duration} / ${info.deadline_label}`, value: info.deadline, deadline: info.deadline, duration: info.duration }));
                    const date_val = {
                        label: 'Deadline',
                        value: 0,
                        deadline: '',
                        duration: ''
                    };
                    setDateDropdown(dateArr);
                    setDateValue(date_val);

                    localStorage.setItem(
                        "calculator_deadline",
                        JSON.stringify({
                            digit: date_val.deadline,
                            durations: date_val.duration,
                            label: `${date_val.label}`
                        })
                    );
                }
            })
            .catch((error) => console.error(`Error: ${error}`));
    }

    // API calling of get Pages
    const getPages = () => {
        if (displayPagesData) {
            const pageArr = displayPagesData.map((info, key) => ({ label: info.Pages, value: info.id, index: key }));
            setWordDropdown(pageArr);
            setWordValue(pageArr[0]);
            setWordId(pageArr[0].value);
            setWordIndex(0)
        }
    }

    const setCoupon = () => {
        // get coupen code from URL
        let coupon = "";

        const router = props.router;
        let ccode = router.query.discount_code || router.query.code;
        if (ccode && ccode !== "") {
            coupon = ccode;
        } else {
            const loginToken = localStorage.getItem("token");
            const convertedToken = loginToken ? JSON.parse(loginToken) : null;
            const decodeToken =
                convertedToken && convertedToken.value
                    ? jwtDecode(convertedToken.value)
                    : null;
            if (
                decodeToken &&
                decodeToken.account &&
                decodeToken.account.orders_count
            ) {
                if (decodeToken.account.orders_count <= 0) {
                    coupon = "SIGNUP15";
                }
            } else {
                coupon = "SIGNUP15";
            }
        }
        setCouponCode(coupon);
    }

    const watchVideoToggle = () => {
        setWatchVideo(!watchVideo);
    }

    const changeServiceHandler = (e) => {
        localStorage.setItem(
            "calculator_pages",
            JSON.stringify(e)
        );

        console.log("service => ",e, e.value);
        setServiceId(e.value);
        setServiceValue(e);
        setAcademicType([42,37,27,41].includes(e.value) ? 2 : 3);
    }

    const changeDateHandler = (e) => {
        localStorage.setItem(
            "calculator_deadline",
            JSON.stringify({ digit: e.deadline, durations: e.duration, label: e.label })
        );

        setDateValue(e);
        setDeadline(e.deadline);
        setDuration(e.duration);
        setDeadlineLabel(e.label);
    }

    const changeValueHandler = async (val) => {
        const filtered = writingDropdown && writingDropdown.length > 0
            && writingDropdown.filter(el => el.value <= val);

        localStorage.setItem(
            "calculator_service",
            JSON.stringify(filtered[0])
        );

        val = parseInt(val);
        console.log("writeValue => ",val);

        setWriteValue(val);
        setIsServiceUpdated(true);
    }

    const decrementWord = () => {
        if (wordIndex > 0) {
            const newWord = wordDropdown[wordIndex - 1]
            changeWordHandler(newWord);
        }
    }

    const incrementWord = () => {
        const newIndex = wordIndex + 1;
        if ((wordDropdown.length - 1) >= newIndex) {
            const newWord = wordDropdown[newIndex];
            changeWordHandler(newWord);
        }
    }

    const changeWordHandler = (e) => {
        localStorage.setItem(
            "calculator_word",
            JSON.stringify({ id: e.value, label: e.label })
        );

        setWordValue(e);
        setWordId(e.value);
        setWordIndex(e.index)
    }

    const setOrderData = async () => {

        const formData = new FormData();
        formData.append("service", writeValue);
        formData.append("paper", serviceId);
        formData.append("academic", academicType);
        if(writeValue === 2){
            // when powerpoint option selected
            formData.append("slide", wordId);
            formData.append("page", 0);

            // formData.append("speaker_notes", 0);
        }else{
            formData.append("page", wordId);
        }
        formData.append("deadline", deadline);
        formData.append("duration", duration);
        formData.append("deadlineLable", deadlineLabel);
        formData.append("coupon_code", couponCode);
        formData.append("currency_id", getCurrencyId(currLocale));

        apiHelper("setOrderV1", "POST", formData, null)
            .then((res) => {
                if (res.data.status) {
                    const token = res.data.data.order_token;
                    const orderData = jwtDecode(token);

                    console.log("orderData",orderData);
                    localStorage.setItem("orderToken", token);
                    setOrder(orderData);
                }
            })
            .catch((error) => console.error(`Error: ${error}`));
    }

    const fetchPagesData = () => {
        const token = localStorage.getItem("orderToken");
        const formData = new FormData();
        formData.append("order_token",token);
        apiHelper("displaypages", "POST", formData, null).then(res => {
            const display_pages_data = res.data.status ? res.data.data : null;
            setDisplayPagesData(display_pages_data);
            setIsServiceUpdated(false);
        });
    }

    useEffect(()=>{
        if(isServiceUpdated){
            fetchPagesData();
        }
    },[orderData])

    useEffect(() => {
        setOrderData();
    }, [writeValue, serviceValue, deadlineLabel, wordIndex]);

    useEffect(()=>{
        getPages();
    },[displayPagesData])

    const { width } = useDeviceWidth();

    return (
        <header style={{ backgroundImage: heroImage ? 'url(' + `${heroImage}` + ')' : 'none' }}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <div className={styles.cheapestDetail}>
                            <h1 className={styles.title}>
                                {initial_content
                                    ? parse(initial_content)
                                    : ""}
                            </h1>
                            <p className={styles.desc}>
                                {initial_pitch_header}
                            </p>
                            <div className="d-sm-flex align-items-center justify-content-center justify-content-lg-start">
                                <Link
                                    href={'/order'}
                                    locale={currLocale}
                                    prefetch={false}
                                    className="btn secondary-btn">
                                    
                                        Write my paper
                                    
                                </Link>
                                <div
                                    className={`${styles.playVideo} d-flex align-items-center`}
                                    onClick={watchVideoToggle}
                                >
                                    {/*<div className={styles.icon}>*/}
                                        <Image src={playCircle} alt="Watch Video" width="54" height="54" />
                                    {/*</div>*/}
                                    <span className="ms-3">Watch Video</span>
                                </div>
                            </div>
                            <WatchVideoModal
                                isOpen={watchVideo}
                                toggle={watchVideoToggle} />
                            {width > 991 && <table className={`${styles.tabList} ${styles.list} d-none d-lg-block`}>
                                <tbody>
                                <tr>
                                    {
                                        guarantees_header_section
                                        && guarantees_header_section.length > 0
                                        && guarantees_header_section.map((data) => <td key={data.entry}>
                                            <p>{parse(data.entry)}</p></td>)
                                    }
                                </tr>
                                </tbody>
                            </table>
                            }
                        </div>
                        {width > 991 && <div className={`${styles.bottomStar} d-none d-lg-block`}>
                            <HeaderRating/>
                        </div>
                        }
                    </Col>
                    <Col lg={5}>
                        <div className={styles.headerRight}>
                            <div className="form-bg-img" />
                            <div className={styles.orderForm}>
                                {writingDropdown?.length > 0 &&
                                    allServiceDropdown?.length > 0 &&
                                    dateDropdown?.length > 0 &&
                                    wordDropdown?.length > 0 ?
                                    <div className="d-block w-100">
                                        <div className={styles.title}>Calculate Your Order</div>
                                        <div className={`${styles.calculateDropdown} calculateOrder`}>
                                            <div className={styles.formGroup}>
                                                <ButtonGroup className={`${styles.list} d-flex flex-nowrap`}>
                                                    {writingDropdown.length > 0 && writingDropdown.map((radio, idx) => (
                                                        <div className={styles.listRdo} key={radio.value}>
                                                            <ToggleButton id={`radio-${idx}`} type="radio" className={styles.btn}
                                                                name="radio" value={radio.value}
                                                                checked={radio.value === writeValue}
                                                                onChange={(e) => changeValueHandler(e.currentTarget.value)}>
                                                                {radio.name}
                                                            </ToggleButton>
                                                        </div>
                                                    ))}
                                                </ButtonGroup>
                                            </div>
                                            <div className={styles.formGroup}>
                                                    <Select
                                                        instanceId="paperDD"
                                                        value={serviceValue}
                                                        onChange={changeServiceHandler}
                                                        options={allServiceDropdown}
                                                        classNamePrefix="selectInput"
                                                        isSearchable={width >= 1199}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    />
                                            </div>
                                            <div className={styles.formGroup}>
                                                    <Select
                                                        instanceId="dateDD"
                                                        value={dateValue}
                                                        onChange={changeDateHandler}
                                                        options={dateDropdown}
                                                        classNamePrefix="selectInput"
                                                        isSearchable={width >= 1199}
                                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        ref={selectRef}
                                                    />
                                                    <p className={styles.errorText}>{selectValidation}</p>
                                            </div>
                                            <div className={`${styles.formGroup} ${styles.wordPageMenu}`}>
                                                <div className={`${styles.numberOfPages} d-flex align-items-center selectMenu`}>
                                                    <span className={`${styles.counter} ${styles.decrement}`} onClick={decrementWord}>&#8722; </span>
                                                        <Select
                                                            instanceId="wordDD"
                                                            value={wordValue}
                                                            onChange={changeWordHandler}
                                                            options={wordDropdown}
                                                            classNamePrefix="selectInput"
                                                            isSearchable={width >= 1199}
                                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />

                                                    <span className={`${styles.counter} ${styles.increment}`} onClick={incrementWord}> &#43;</span>
                                                </div>
                                            </div>
                                            <div className={`${styles.calculatePrice} d-flex align-items-center justify-content-between mt-4 mb-3`}>
                                                {orderData && orderData.index_discount && orderData.index_discount !== '' ? <span className={styles.off}>{orderData.index_discount}</span> : null}
                                                <div className={styles.standPrice}>
                                                    {t("currency_name")} {orderData?.totalPrice ? orderData.totalPrice : "0.00"}
                                                    {
                                                        orderData?.index_discount &&
                                                        <span className="ms-2">(<span className={styles.orderPrice}>{t("currency_name")} {orderData.price_without_discount ? orderData.price_without_discount : "0.00"}</span>)</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.bottomPart}>
                                            <button
                                                className={`btn ${styles.orderBtn} secondary-btn inline-button-click text-start`}
                                                onClick={orderSubmitHandler}
                                                id="orderNow"
                                            >
                                                Order Now
                                            </button>
                                        </div>
                                    </div> :
                                    <div className={styles.loader}>
                                        <Spinner animation="border" />
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                {width <= 991 && (
                    <>
                        <div className={styles.cheapestDetail}>
                            <table className={`${styles.tabList} ${styles.tabMob} ${styles.list} d-md-block d-lg-none`}>
                                <tbody>
                                <tr>
                                    {(
                                        props.router && props.router.pathname && props.router.pathname !== "" &&
                                        (props.router.pathname === "/" || props.router.pathname === "/index")
                                    ) ? (
                                        <>
                                            <td><p>Cheapest in the market</p></td>
                                            <td><p>Your data is 100% safe</p></td>
                                            <td><p>Plagiarism free work</p></td>
                                            <td><p>24/7 customer support</p></td>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                serviceData &&
                                                serviceData.guarantees_header_section &&
                                                serviceData.guarantees_header_section.length > 0 &&
                                                serviceData.guarantees_header_section.map(
                                                    (data, index) => <td key={index}>{parse(data.entry)}</td>
                                                )
                                            }
                                        </>
                                    )
                                    }
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`${styles.bottomStar} d-md-block d-lg-none`}>
                            <HeaderRating />
                        </div>
                    </>
                )}
            </Container>
        </header>
    );
}

export default withRouter(Header);